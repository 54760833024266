const httpClient = require('@/services/http-client');

export async function postLogin({ username, password }) {
  const response = await httpClient.post('/api/auth/login', {
    username,
    password,
  }).catch((err) => err.response);

  return response.data;
}

export async function postLogout() {
  const response = await httpClient.post('/api/auth/logout').catch((err) => err.response);

  return response.data;
}

export async function getSessionInformation() {
  const response = await httpClient.get('/api/auth/session').catch((err) => err.response);

  return response.data;
}

export async function postChangeTemporaryPassword(payload) {
  const response = await httpClient.post('/api/adm/users/change-temp-password', payload)
    .catch((err) => err.response);

  return response.data;
}
